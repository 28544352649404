// Component states
import states from './settings-navbar-link-modifier.state.js'
import workspace from '@/components/parts/workspace/workspace.state'
import custColorPicker from '@/components/standalone/cust-color-picker/Cust-color-picker.vue'

// Components
import textConf from '@/assets/config/mjml-components/mj-text.conf'
import MdiAccount from 'vue-material-design-icons/Account.vue'

// Services
import { getDefaultStyles } from '@/services/dnd-style-engine/dnd-style-engine.service'
import { landingPageMode, readingDirection, lockEditPanel } from '@/services/states/states.service'
import { ckTranslator, eventEmit } from '@/services/utils/utils.service'


// Default style conf
// import textDefaultStyle from '@/assets/config/default-style/text.conf'

// Library
import {
  cloneDeep as _cloneDeep,
  get as _get
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-mj-link-navbar'

// Properties
const props = {
  conf: Object,
  mjml: Object
}

// Vue@data
const data = function () {
  return {
    states: _cloneDeep(states),
    workspace,
    focused: false
  }
}
// Vue@data

// Components
const components = {
  textConf,
  custColorPicker,
  MdiAccount
}

// Methods
const methods = {
  // Func@checkError
  /**
   * Check if corresponding error is detected
   */
  checkError () {
    const alertInConfig = _get(this.conf, 'alertType') === 'wrong_url'
    this.states.hasError = alertInConfig && !this.isUrlValid && this.states.href.length
    this.states.hasError ? lockEditPanel(true, 'wrong_url') : lockEditPanel(false)
  },
  // Func@checkError

  // Func@revertReadingDirection
  /**
   * Enable or disable RTL option
   * @param  {Boolean} isRtl (active or not)
   */
  revertReadingDirection (isRtl) {
    this.states.loading = true
    this.states.revertReadingDirection = isRtl
    this.states.editorConfig.language.content = isRtl ? 'ar' : 'en'
    if (_get(this.mjml.attributes, 'css-class')) this.mjml.attributes['css-class'] = isRtl ? 'is-rtl' : ''

    this.mjml.content = ckTranslator({
      entry: this.content,
      dest: 'MJML',
      options: {
        isRtl,
        onSwitch: true,
        isEnforcedLTR: readingDirection().get() === 'rtl'
      }
    })

    this.$nextTick(() => {
      this.states.loading = false
      this.updateEditorStyle()
    })
  },
  // Func@revertReadingDirection

  // Func@updateFontColor
  /**
   * Update color on editor
   * @param  {String} val (hexa)
   */
  updateFontColor (val) {
    this.mjml.attributes.color = val
    this.updateEditorStyle()
  },

  // Func@updateEditorStyle
  /**
   * Update background color of ckEditor content
   * depending layers of colors
   */
  updateEditorStyle () {
    const textColor = this.mjml.attributes.color
    const fontSize = this.mjml.attributes['font-size']
    const fontFamily = this.mjml.attributes['font-family']

    setTimeout(() => {
      if (!this.$refs.CKEditor) return

      const ckEditorContent = this.$refs.CKEditor
        .querySelector('.ck-editor__main')

      ckEditorContent.style.color = textColor
      ckEditorContent.style['font-size'] = fontSize
      ckEditorContent.style['font-family'] = fontFamily
    }, 300)
  },
  // Func@updateEditorStyle

  /**
   * Set dirty field if url has been updated
   */
  setDirtyField () {
    this.$set(this.states, 'dirtyField', true)
  },

  /**
   * Function to handle events
   * can set the personalization field text in input.
   * @param  {data} object   (object sent from event)
   */
   handleEvents (event) {
    if (typeof event.data !== 'object') { return }
    const call = event.data.call
    const value = event.data.value
    if (call == 'personalization-fields-manager:text') {
      if (!value.text || value.fallback !== 'settings-navbar')
        return
      var cursorPosition = document.querySelector("#settings-navbar-link-input").selectionStart

      if (this.href.length === 0)
        this.states.href = value.text
      else if (cursorPosition > 0)
        this.states.href = (
          this.states.href.slice(0, cursorPosition)
          + value.text
          + this.states.href.slice(cursorPosition)
        )
      else
        this.states.href += value.text
    }
  },

  /**
  * Function to handle opening of personalization fields manager
  */
    openPersonalizationFieldsManager: function () {
    eventEmit('open-personalization-fields-manager', { fallback: 'settings-navbar' })
  },
}

// Computed Methods
const computed = {
  // Func@isEnforcedLTR
  /**
   * Check if text is enforced left to right (in RTL default context)
   * @returns {Boolean}
   */
  isEnforcedLTR () {
    return this.mjml.content && this.mjml.content.includes('dir="ltr"')
  },
  // Func@isEnforcedLTR

  // Func@isRTL
  /**
   * Check reading direction
   * @return {Boolean}
   */
  isRTL () {
    if (!this.mjml.content) return readingDirection().get() === 'rtl'
    return !this.isEnforcedLTR && (this.mjml.content.includes('dir="rtl"') || readingDirection().get() === 'rtl')
  },
  // Func@isRTL

  href: {
    // getter
    get () {
      return this.states.href.replace(/&quot;/g, '"')
    },
    // setter
    set (newValue) {
      const val = newValue.replace(/"/g, '&quot;') // Fix MJML issue with with `"` in html attr
      this.$set(this.states, 'href', val)

      if (this.isUrlValid || !_get(this.conf, 'alertType')) {
        this.$set(this.mjml.attributes, 'href', this.states.href)
      }
    }
  },

  /**
   * Check if url is valid
   * @returns {Boolean}
   */
    /**
   * Check if url is valid
   * @returns {Boolean}
   */
  isUrlValid () {
    if (this.states.href.length > 0) {
      let rgx = ''
      if (this.states.href.includes('http')) {
        const link = /(http|https)?:\/\/(?:www\.|(?!www\.))([a-zA-Z0-9-[\]=#_/+%!]+)?([-]{0,1}[a-zA-Z0-9-[\]=#_/+%!]){0,}((?:{{[^}{]{1,}}})|(?:%%[^%]{1,}%%)|(?:<%[^%<>]{1,}%>)|(?:#[^#\s]{1,})){0,}([a-zA-Z0-9-[\]=#_/+%!]+)?([-]{0,1}[a-zA-Z0-9-[\]=#_/+%!])?(?:\.(([a-zA-Z0-9-[\]=#_/+%!:,']){0,}((?:{{[^}{]{1,}}})|(?:%%[^%]{1,}%%)|(?:<%[^%<>]{1,}%>)|(?:#[^#\s]{1,})){0,})){1,}(?:\?(([a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}((?:{{[^}{]{1,}}})|(?:%%[^%]{1,}%%)|(?:<%[^%<>]{1,}%>)|(?:#[^#\s]{1,})){0,})([a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}){0,}(?:&(([a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}((?:{{[^}{]{1,}}})|(?:%%[^%]{1,}%%)|(?:<%[^%<>]{1,}%>)|(?:#[^#\s]{1,})){0,}([a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}((?:{{[^}{]{1,}}})|(?:%%[^%]{1,}%%)|(?:<%[^%<>]{1,}%>)|(?:#[^#\s]{1,})){0,})([a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}([-]{0,1}[a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}){0,}/gm
        rgx = new RegExp(link)
        return this.states.href.match(rgx) && this.states.href.match(rgx)[0].length === this.states.href.length
      } else {
        const perso = /(?:{{[^}{]{1,}}}){0,}([a-zA-Z0-9-[\]=#_\\/+%!?&.]){0,}(?:{{[^}{]{1,}}}){0,}|(?:%%[^%]{0,}%%){0,}[a-zA-Z0-9-[\]=#_\\/+%!?&.]{0,}(?:%%[^%]{1,}%%){0,}|(?:<%[^%<>]{1,}%>){0,}[a-zA-Z0-9-[\]=#_\\/+%!?&.]{0,}(?:<%[^%<>]{1,}%>){0,}|(?:#[^#\s]{1,}){0,}[a-zA-Z0-9-[\]=#_\\/+%!?&.]{0,}(?:#[^#]{1,}#){0,}/gm
        rgx = new RegExp(perso)
        return this.states.href.match(rgx) && this.states.href.match(rgx).join('').length === this.states.href.length
      }
    }
    return true
  },

  // Func@fonts
  /**
   * Font list for default conf
   * @return {Array} (ckeditor fonts - default option)
   */
  fonts () {
    const fonts = _cloneDeep(states.editorConfig.fontFamily.options)
    fonts.shift() // remove default entry

    return fonts
  },
  // Func@fonts

  // Func@getLinksStyles
  /**
   * Get links default style
   * @return {String} (Links CSS properties)
   */
  getLinksStyles () {
    return getDefaultStyles().links()
  },
  // Func@getLinksStyles

  // Func@content
  /**
   * Content text without language format option
   * (managed directly via CKEDITOR config file)
   * @get {String} (Current text html)
   * @set (String) (Updated text html)
   */
   content: {
    // getter
    get () {
      return ckTranslator({
        entry: this.mjml.content,
        dest: 'CKE',
        options: {
          isRtl: this.states.revertReadingDirection,
          onSwitch: this.states.loading,
          isEnforcedLTR: readingDirection().get() === 'rtl'
        }
      })
    },
    // setter
    set (html) {
      this.mjml.content = ckTranslator({
        entry: html,
        dest: 'MJML',
        options: {
          isRtl: this.states.revertReadingDirection,
          onSwitch: this.states.loading,
          isEnforcedLTR: readingDirection().get() === 'rtl'
        }
      })
    }
  },
  // Func@content

  // Func@isLandingMode
  /**
   * Check DND mode
   */
  isLandingMode () {
    return landingPageMode().get()
  },
  // Func@isLandingMode

  // Func@color
  /**
   * Get content colors from MJML template
   * @returns {String} (ex: #000000)
   */
  color () {
    return this.mjml.attributes.color
  },
  // Func@color

  // Func@lineHeight
  /**
   * Text line-height
   * @type {Object}
   */
  lineHeight: {
    // getter
    get () {
      return parseInt(this.mjml.attributes['line-height'], 10)
    },
    // setter
    set (newValue) {
      this.$set(this.mjml.attributes, 'line-height', `${newValue}px`)
      this.updateEditorStyle()
    }
  },
  // Func@lineHeight

  // Func@fontSize
  /**
   * Font size modifier
   */
  fontSize: {
    // getter
    get () {
      return parseInt(this.mjml.attributes['font-size'], 10)
    },
    // setter
    set (newValue) {
      this.$set(this.mjml.attributes, 'font-size', `${newValue}px`)
      this.updateEditorStyle()
    }
  }
  // Func@fontSize
}

// Vue@watchTemplate (disabled for now)
const watch = {
  'states.href': {
    handler () {
      this.checkError()
    },
    deep: true
  }
}
// Vue@watchTemplate

// Func@mounted
/**
 * Set rtl options on create
 */
function mounted () {
  this.states.href = this.mjml.attributes.href
  if (this.isRTL) {
    setTimeout(() => {
      this.revertReadingDirection(true)
    })
  }
  this.updateEditorStyle()
}
// Func@mounted


// Func@addWindowListener on create
/**
 * Add window resize event listener and define save html
 * request to be able to flush it on destroy
 */
 function created () {
  this.states.href = this.mjml.attributes.href
  window.addEventListener('message', this.handleEvents)
}
// Func@addWindowListener

// Func@removeWindowListener on destroy
/**
 * Remove window resize event listener
 */
function destroyed () {
  window.removeEventListener('message', this.handleEvents)
}
// Func@removeWindowListener

// Vue component syntax
export default {
  name,
  data,
  props,
  watch,
  mounted,
  methods,
  computed,
  components,
  created,
  destroyed
}
